"use strict";
exports.__esModule = true;
exports.initIE11Warning = void 0;
var wagtailConfig_1 = require("../config/wagtailConfig");
/**
 * Toggles IE11-only warning messages, if any.
 */
var initIE11Warning = function () {
    if (!wagtailConfig_1.IS_IE11) {
        return;
    }
    var warnings = [].slice.call(document.querySelectorAll('[data-ie11-warning]'));
    warnings.forEach(function (warning) {
        // eslint-disable-next-line no-param-reassign
        warning.hidden = false;
    });
};
exports.initIE11Warning = initIE11Warning;
