"use strict";
exports.__esModule = true;
exports.versionOutOfDate = exports.compareVersion = void 0;
function compareVersion(versionA, versionB) {
    var re = /(\.0)+[^.]*$/;
    var va = (versionA + '').replace(re, '').split('.');
    var vb = (versionB + '').replace(re, '').split('.');
    var len = Math.min(va.length, vb.length);
    for (var i = 0; i < len; i++) {
        var cmp = parseInt(va[i], 10) - parseInt(vb[i], 10);
        if (cmp !== 0) {
            return cmp;
        }
    }
    return va.length - vb.length;
}
exports.compareVersion = compareVersion;
function versionOutOfDate(latestVersion, currentVersion) {
    return compareVersion(latestVersion, currentVersion) > 0;
}
exports.versionOutOfDate = versionOutOfDate;
